import Head from 'next/head'
import loginPageStyles from '@/modules/login-page/LoginPage.module.scss'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import { Login } from '@/components/Login/Login'
import { LoginWidget } from '@/components/LoginWidget/LoginWidget'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import USBNotification from '@usb-shield/react-notification'
import React, { useState } from 'react'

const sixColsOption = {
  span: null,
  spans: {
    xlarge: 6,
    large: 7,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'top',
  justify: 'left',
}

export const LoginPage = ({ headerData }: { headerData: any }) => {
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  // Alert message to render for errors
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [loginWidgetIsOpen, setLoginWidgetIsOpen] = useState(true)
  const loginWidgetCallback = async (
    status: boolean | null,
    message: string,
    showAlert: boolean = false
  ) => {
    if (status != null) setLoginWidgetIsOpen(status)
    setShowAlert(false)
    if (showAlert) {
      setShowAlert(true)
    } else if (message != '') setAlertMessage(message)
    else setAlertMessage(message)
  }

  return (
    <>
      <div>
        <Head>
          <title>Log in | Developer Portal</title>
        </Head>
        <div className={loginPageStyles.main}>
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <USBGrid padding="normal" addClasses={loginPageStyles.gridWrapper}>
            <USBColumn layoutOpts={sixColsOption}>
              {!featureMFA && <Login></Login>}
              {featureMFA && showAlert && alertMessage?.length > 0 && (
                <USBNotification
                  variant="error"
                  id="login_fail"
                  dataTestId="login-error-notification"
                  iconAssistiveText={{ label: 'Error' }}
                  roleType="alert"
                  notificationData={[
                    {
                      text: alertMessage,
                      linkText: null,
                      link: null,
                    },
                  ]}
                  addClasses={loginPageStyles.notification}
                />
              )}
              {featureMFA && loginWidgetIsOpen && (
                <div className={loginPageStyles.contentBlock}>
                  <LoginWidget
                    authWidgetConfigData={headerData.authWidgetConfigData}
                    loginWidgetCallback={loginWidgetCallback}
                  ></LoginWidget>
                </div>
              )}
            </USBColumn>
          </USBGrid>
        </div>
      </div>
      {/* MFA Auth widget css issue fix  */}
      <style>{`
        .aw-usb-authenticate {
          padding-left: 0 !important;
        }
      `}</style>
      {/* MFA Auth widget css issue fix */}
    </>
  )
}
